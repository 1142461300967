<template>
  <div class="wrapper">
    <div class="cancel">
      <router-link to="/">
        <CloseIcon />
      </router-link>
    </div>

    <div class="auth-banner">
      <img src="/img/chop-banner.webp" style="width: 100%; border-radius: 10px; max-width: 100%" />
    </div>

    <section class="register-form-wrapper" v-show="action_signup">
      <div class="heading">
        <h3>{{ $t("signUp") }}</h3>
        <p>{{ $t("letKnowYou") }}</p>
      </div>
      <div class="form-wrapper mb-1">
        <form id="login-form">
          <TextInput
            type="phoneNumber"
            v-model="msisdn"
            placeholder="Phone number"
            aria-autocomplete="none"
          />
          <TextInput
            type="password"
            v-model="password"
            placeholder="Password"
          />

          <div class="disclaimer">
            {{ $t("atLeast6CharactersContaining") }}
            <strong>{{ $t("aLetter") }} </strong> and
            <strong>{{ $t("aNumber") }} </strong>.
          </div>

          <h6>{{ $t("optionalDetails") }}</h6>

          <div class="phone-number">
            <input
              type="email"
              class="form-control"
              :placeholder="$t('emailAddress')"
              aria-label="Email Address"
              name="email"
              id="email"
              autocomplete="off"
              v-model="email"
            />
          </div>

          <div class="phone-number">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('promoCode')"
              aria-label="Promo code"
              name="promo"
              id="promo"
              v-model="promo"
            />
          </div>

          <div class="form-check">
            <input
              class="form-check-input custom-checkbox"
              type="checkbox"
              value=""
              id="flexCheckChecked1"
              v-model="agreeLegalBetting"
              required
            />
            <label class="form-check-label" for="flexCheckChecked1">
              {{ $t("agreeToLegalBetting") }}
            </label>
          </div>

          <div class="form-check">
            <input
              class="form-check-input custom-checkbox"
              type="checkbox"
              value=""
              id="flexCheckChecked2"
              v-model="agreeTandC"
              required
            />
            <label class="form-check-label" for="flexCheckChecked2">
              {{ $t("iHaveReadAgreeChopbet") }}
              <span>
                <router-link style="color: #cc1717" to="/terms">{{
                  $t("termsConditions")
                }}</router-link> </span
              >.
            </label>
          </div>
        </form>
      </div>

      <ChopbetButton
        :loading="spinner"
        :disabled="!agreeLegalBetting || !agreeTandC"
        buttonClass=""
        variant="Variant"
        @click="handleSignupClick"
        ><span style="text-transform: uppercase">{{ $t("continue") }}</span>
      </ChopbetButton>

      <div class="already-join">
        <p>{{ $t("alreadyHaveAccount") }}</p>
        <router-link to="/login">
          <span class="special-font" style="color: var(--red)">
            <strong>{{ $t("login") }}</strong>
          </span>
        </router-link>
        <br />
      </div>
    </section>
  </div>
</template>

<script>
import axios from "@/services/identity";
import { pushToDataLayer } from "@/utils/gtm";
import { mapState } from "vuex";
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import TextInput from "../../../components/ui/TextInput.vue";
import CloseIcon from "../components/CloseIcon.vue";


export default {
  name: "Login",
  components: {
    TextInput,
    ChopbetButton,
    CloseIcon,
  },
  comments: {},
  data: function () {
    return {
      first_name: "",
      last_name: "",
      email: "",
      msisdn: "",
      password: "",
      password1: "",
      error: [],
      warning: [],
      success: [],
      loading: "",
      spinner: false,
      action_signup: true,
      placeBet: 0,
      type: "password",
      type2: "password",
      type3: "password",
      btnText: "/img/icons/eye.svg",
      btnText2: "/img/other/eye.svg",
      btnText3: "/img/other/eye.svg",
      showSuccessModal: false,
      code: new Array(4).fill(""),
      agreeLegalBetting: false,
      agreeTandC: false,
    };
  },
  computed: {
    phoneInvalid: function () {
      if (this.msisdn.length < 10 && this.msisdn.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState(["referralCode"]),
  },
  methods: {
    handleBlur: function () {
      console.log(
        this.msisdn.length,
        "this.msisdn.lengththis.msisdn.lengththis.msisdn.length"
      );
      if (this.msisdn.toString().length < 9 && this.msisdn.length > 0) {
        this.setError("Please enter a valid mobile number");
        document.getElementById("phoneInput").classList.add("is-invalid");
      } else {
        document.getElementById("phoneInput").classList.remove("is-invalid");
      }
    },

    getBrowserInfo() {
      return navigator.userAgent || "unknown";
    },

    getDeviceInfo() {
      return `${navigator.platform} ${navigator.userAgent}`;
    },

    getEngineInfo() {
      return "JavaScript";
    },

    getVersionInfo() {
      return navigator.appVersion || "unknown";
    },

    handleSignupClick(event) {
      this.signup(event);
    },
    trackSignupButtonClick(event) {
      pushToDataLayer("gtm.register", {
        category: "Button",
        action: "Click",
        label: "Register",
        element: event.target,
      });
    },
    signup: function (event) {
      this.reset();

      this.msisdn = this.msisdn.replace(/\D/g, "");

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {
        this.setError("Please enter a valid mobile number");
        return;
      }

      if (this.password.length < 4) {
        this.setError("Please enter a password of more than 4 characters");
        return;
      }

      if (this.email !== "") {
        if (this.validateEmail()) {
          return;
        }
      }

      this.loading = "loading";
      this.spinner = true;

      var vm = this;
      var path = "/signup";

      var browser = this.getBrowserInfo();
      var deviceInfo = this.getDeviceInfo();
      var engine = this.getEngineInfo();
      var versionInfo = this.getVersionInfo();

      var channelId = parseInt(this.getValue("channel_id")) || 0;

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var btag = this.getValue("btag");

      console.log("Register method triggered");
      console.log("Document Referrer:", document.referrer);

      console.log("Register method triggered");
      console.log("Document Referrer:", document.referrer);
      console.log("Referral Code:", this.referralCode);
      const numberValue = `225${this.msisdn}`;

      const signUpPayload = {
        browser: browser,
        channel_id: channelId,
        device_info: deviceInfo,
        engine: engine,
        version_info: versionInfo,
        msisdn: parseInt(numberValue),
        password: this.password,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer,
        referral_code: this.referralCode,
        btag: btag,
      };
      if (this.email) {
        signUpPayload.email = this.email;
      }
      axios
        .post(path, JSON.stringify(signUpPayload))
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";

          console.log("Response:", JSON.stringify(res, undefined, 2));

          var status = res.status;

          if (parseInt(status) === 202) {
            vm.$router.push("/login");
            return;
          }

          if (parseInt(status) === 201 || parseInt(status) === 204) {
            localStorage.setItem("mssdn__reg_xpk", numberValue);
            this.$router.push("/activate-account");
            return;
          }

          vm.trackSignupButtonClick(event);

          vm.setSuccess("Success", "Signup successful!");
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";

          if (err.response) {
            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err.request));
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err));
          }
        });
    },
    trackResetButtonClick(event) {
      pushToDataLayer("gtm.reset", {
        category: "Button",
        action: "Click",
        label: "Reset",
        element: event.target,
      });
    },
    handleInput(index, event) {
      if (event.target.value.length === 1) {
        const nextInput = this.$el.querySelectorAll(".otp__input")[index + 1];
        if (nextInput) nextInput.focus();
      }
    },
    handleBackspace(index) {
      if (this.code[index] === "") {
        const prevInput = this.$el.querySelectorAll(".otp__input")[index - 1];
        if (prevInput) prevInput.focus();
      }
    },
    showPassword: function () {
      if (document.getElementById("inputPass").type == "password") {
        document.getElementById("inputPass").type = "text";
        document.getElementById("inputPass").style.color = "white";
      } else {
        document.getElementById("inputPass").type = "password";
        document.getElementById("inputPass").style.color =
          "rgba(255,255,255,.75)";
      }
      // if(document.getElementById('signupPassword1').type == "password"){
      //   document.getElementById('signupPassword1').type = "text"
      // }else{
      //   document.getElementById('signupPassword1').type = "password"
      // }
      if (this.type === "password") {
        this.type = "text";
        this.btnText = "/img/icons/eye-slash.svg";
      } else {
        this.type = "password";
        this.btnText = "/img/icons/eye.svg";
      }
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email.match(emailPattern)) {
        this.setError("Email is not correct");
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage", "join");
    this.placeBet = this.getValue("placeBet");
  },
};
</script>

<style src="./index.css" scoped></style>
